@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');* {
  box-sizing: border-box;
}

html{
  --bg: #FCFCFC;
  --bg-panel: #EBEBEB;
  --color-text: #333333;  
  --color-border: #cccccc;
  --bg-cover: rgba(255,255,255,0.75);
  --bg-cover2: rgba(255,255,255,0.9);
  --heading-color: #000;
  --accent-color: #ED1C24;
  --icon-bar: #dddddd;
}

html[data-theme='dark'] {
  --bg: #333333;
  --bg-panel: #434343;
  --color-text: #B5B5B5;
  --color-border: #666666;
  --bg-cover: rgba(50,50,50,0.75);
  --heading-color: #B5B5B5;
  --icon-bar: #555555;
}
button, input, select, textarea{
  text-transform: uppercase;
  box-shadow: 0px 0px 5px #333333;
  border: 0px;
}
label{
  display: block;
  padding: 10px 5px;
  color: var(--color-text);  
}
input, select, textarea{
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  color: var(--color-text);  
  background: var(--bg); 
  width: 240px;
  font-size: 13px;
  font-family: 'Montserrat', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
  Cantarell, 'Helvetica Neue', Arial, sans-serif;  
}
select, textarea:focus, input:focus{
  outline: none;
}

button, .btn{
  padding: 15px;
  border-radius: 10px;
  background: var(--color-text);  
  color: var(--bg-panel);  
  cursor: pointer;  
}
.btn{
  text-transform: uppercase;
}
.btn:hover{
  color: var(--color-text);  
  background: var(--bg-panel);  
}
button:disabled,
button[disabled]{
  opacity: 0.5;
  cursor: auto;
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

body, button{
  background-color: var(--bg);  
  font-family: 'Montserrat', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu,
  Cantarell, 'Helvetica Neue', Arial, sans-serif;
  font-size: 13px;
  margin: 0px;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}
button {
  user-select: none;
}
h1{
  font-size: 18px;
  text-transform: uppercase;
  margin: 16px 10px;
  color: var(--heading-color);
  text-align: center;
}
h2{
  margin: 10px;
  color: var(--heading-color);
  font-size: 16px;

}
span, li{
  color: var(--color-text);
}
a{
  color: var(--accent-color);
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
.okbg-hs-srchcrit:after{
  content: "x";
  position: absolute;
  top: 0px;
  right: 0px;
  padding-right: 5px;
  font-weight: bold;
  color: var(--accent-color);
  background-color: var(--bg);
}


.okbg-hs-inactive-link{
  opacity: 0.1;
  pointer-events: none;
}

.okbg-hs-flex-container{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  padding: 10px;  
}

#okbg-hs-toggle-holder{
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  width: 140px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.okbg-hs-panel{
  border-width: 1px;
  border-color: var(--color-border);
  border-style: solid;
  background: var(--bg-panel);  
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.okbg-hs-padding5{
  padding: 5px;
}

.okbg-hs-note{
  font-weight: bold;
  padding: 10px;
}
.okbg-hs-note::before{
  content: "!";
  background-color: var(--accent-color);
  border-radius: 50%;
  color: white;
  font-weight: bold;
  margin-right: 5px;
  padding: 5px 12px 5px 13px;
}

hr{
  border-width: 1px;
  border-bottom-color: var(--color-border);
  border-top-color: var(--color-text);
  border-style: solid;
  margin: 15px 0px;
}

/*
HOUSES VIEW
*/
.okbg-hs-home-houses-holder{
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.okbg-hs-houses-holder{
  /*
  za horizontalen scroll
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  */
  display: flex;
  flex-wrap: wrap;
}
.okbg-hs-srchcrit, .okbg-hs-savedsrch{
  display: inline-block;
  margin: 5px;
  padding: 4px 20px 4px 8px;
  color: var(--color-text);
  background-color: var(--bg);
  border-radius: 10px;
  line-height: 18px;
  position: relative;
}
.okbg-hs-srchcrit:hover{
  opacity: 0.7;
}
.okbg-hs-srchcrit span, .okbg-hs-savedsrch span{
  font-weight: bold;
}
.okbg-hs-flex-column{
  flex: 100%;
  padding: 0px 10px;
}
@media (min-width: 600px) {
  .okbg-hs-flex-column {
    flex: 50%;
  }
}
@media (min-width: 1000px) {
  .okbg-hs-flex-column {
    flex: 33%;
  }
}
@media (min-width: 1400px) {
  .okbg-hs-flex-column {
    flex: 25%;
  }
}

.house-panel{
  /*margin:10px; za horizontalen scroll*/

  margin:10px auto;
  width: 280px; height: 370px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #333333;
}
.house-id{
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 7px 10px;
  text-shadow: 0px 0px 10px #000000;
  background-color: rgba(0,0,0,0.1); 
}
.house-id-det{
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  padding: 0px 5px;
  color: var(--accent-color);
}
.house-price-det{
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-text);
}
.house-price{
  padding: 0px 7px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  margin: 5px 0px 0px 0px;
  color: var(--color-text);
}
.house-image-holder{
  width: 280px; height: 220px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  background-size: cover;
  background-position: center center;
}
.house-location{
  font-weight: bold;
}
p{
  margin: 5px 0px;
  color: var(--color-text);
}
p.okbg-hs-location{
  line-height: 20px;
  height: 60px;
  overflow: hidden;
  padding: 0px 7px;
  font-size: 14px;
  margin-top: 0px;
}
.patch-margin-right{
  width: 0px;
  height: 0px;
}
.okbg-hs-updated{
  font-weight: normal;
  font-size: 12px;
  text-align: right;
}
.okbg-hs-iconbar{
  height: 50px;
  margin-top: -10px;
  position: relative;
  background: var(--icon-bar);
  /*
  background: -moz-linear-gradient(top, var(--accent-color) 0%, var(--accent-color) 50%, var(--icon-bar) 51%, var(--icon-bar) 100%);
  background: -webkit-linear-gradient(top, var(--accent-color) 0%,var(--accent-color) 50%,var(--icon-bar) 51%,var(--icon-bar) 100%);
  background: linear-gradient(to bottom, var(--accent-color) 0%,var(--accent-color) 50%,var(--icon-bar) 51%,var(--icon-bar) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#000000',GradientType=0 );
  */
}
.okbg-icontext-holder{
  margin: 5px;
  height: 25px;
  display: inline-block;
}
.okbg-hs-iconbar-text{
  text-align: center;
  font-size: 11px;
  line-height: 25px;
  color: var(--color-text);
  display: block;
  padding: 0px 0px 0px 0px;
}
.okbg-hs-iconbar-icon{
  text-align: center;
  display: block;
  margin: 0 auto;
}

/*HOUSE DETAILS VIEW*/
.okbg-house-image-holder-details{
  border-color: var(--bg);
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #333;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  transition: height 0.5s;
  margin: 10px;
  position: relative;
  z-index: 100000;  
}
.okbg-house-image-holder-details img{
  width: 100%;
  display: block;
  transition: opacity 1s;
}
.okbg-hs-img-loader-holder{
  background: var(--bg-panel);  
  color: var(--color-text);
  position: absolute; 
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  transition: height 0.5s;
}



/* SIDE NAV*/
.okbg-hs-side-nav{
  height:100%;
  width:0px;
  position:fixed;
  z-index:100001;
  top:0;
  right:-10px;
  border-width: 1px;
  border-color: var(--color-border);
  border-style: solid;
  background-color:var(--bg-panel);
  opacity:0.95;
  overflow-x:hidden;
  padding-top:40px;
  transition: width 0.5s;
  box-shadow: 0px 0px 10px black;
}
.okbg-hs-side-nav-link{
  padding:10px 10px 10px 20px;
  text-decoration:none;
  font-size: 15px;
  color:var(--color-text);
  display:block;
  transition:0.3s;
  cursor: pointer;
  width: 250px;
  border-bottom: 1px solid var(--color-border);
}
.okbg-hs-search-bgr{
  background-color: rgba(0,0,0,0.05);
}

.okbg-hs-side-nav-link:hover{
  color:var(--accent-color);
  text-decoration: none;
}
.okbg-hs-side-nav .okbg-hs-btn-close, #closeLoginPopup, #closeMessagePopup{
  position:absolute;
  top:0;right:15px;
  font-size:36px;
  line-height:36px;
  margin: 0px; padding: 0px;
  width: 30px;
}

/*LANGUAGE SELECTOR*/
.lang-button{
  text-transform: lowercase;
  padding: 5px 8px; margin: 5px;
  border: 2px solid black;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
}
.lang-button:hover{
  background-color: white;
  color: black;
  box-shadow: 0px 0px 10px #888888;
}
.lang-button-selected{
  background-color: white;
  color: black;
  box-shadow: 0px 0px 10px #000000;
  pointer-events: none;
}
.okbg-hs-languages-holder{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

/* ANIMATION PANEL */
#okbg-hs-anim-holder{
  position: fixed;
  width: 100%; height: 100%; top: 0px; left: 0px;
  background-color:  rgba(255,255,255,1);
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  z-index: 100002;
  display: flex;
  flex-direction: column;
}

/* LOADING COVER */
#loading-cover, #login-popup, #message-popup{
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  position: fixed;
  width: 100%; height: 100%; top: 0px; left: 0px;
  background-color:  var(--bg-cover2);
  font-size: 20px;
  color: white;
  font-weight: bold;
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  z-index: 100001;
}
.loading-cover-showing, .login-popup-showing, .message-popup-showing{
  opacity: 1 !important;
  pointer-events: all !important;
} 




/* CHECKBOX TOGGLE */
input[type="checkbox"]:focus{
  outline:0;
}
input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}
label.theme-toggle {
	cursor: pointer;
	text-indent: -9999px;
	width: 52px;
	height: 27px;
	background: grey;
	float: right;
	border-radius: 100px;
	position: relative;
}
label.theme-toggle:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}
input:checked + label {
	background: var(--accent-color);
}
input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}
label:active:after {
	width: 45px;
}


/* NEW LANGUAGE SELECTOR */
.okbg-hs-navbar{
  background-color: var(--accent-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 5px;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 10;
  box-shadow: 0px 0px 3px #333333;
}
.okbg-hs-lang-img{
  display: block;
  width: 26px;
  height: 26px;
}
.okbg-hs-lang-btn{
  width: 30px;
  height: 30px;
  display: block;
  padding: 2px;
  position: absolute;
}
.okbg-hs-lang-btn img{
  opacity: 0.5;
  transition: opacity 0.25s;
}
.okbg-hs-lang-btn img:hover{
  opacity: 1;
}
.okbg-hs-lang-selected{
  width: 30px;
  height: 30px;
  display: block;
  padding: 2px;
  position: absolute;
  cursor: pointer;
}
.okbg-hs-lang-slider{
  position: relative;
  display: flex;
  transition: left 0.25s;
}
.okbg-hs-lang-holder{
  width: 30px;
  height: 30px;
  overflow: hidden;
  transition: width 0.25s;
}
.okbg-hs-lang-holder-opened{
  width: 150px;
}
#okbg-hs-change-lang{
  width: 44px;
  height: 24px;
  
  position: relative;
  /*
  right: 43px;
  top: 8px;
  */
  cursor: pointer;
  transition: left 0.5s;
  opacity: 0.7;
}
#okbg-hs-change-lang:hover{
  opacity: 1;
}
.okbg-hs-secondary-price{
  font-size: 12px;
}


/* INPUT TYPE RANGE*/
input[type='range'] {
  width: 100%;
  max-width: 500px;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  padding: 0px 5px;
  margin: 0px;
}
input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: none;
  pointer-events: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  max-width: 500px;
  height: 1px;
  background: var(--color-text);
}

input[type='range']:nth-child(2)::-webkit-slider-runnable-track{
  background: none;
}

input[type='range']::-webkit-slider-thumb {
  position: relative;
  height: 30px;
  width: 30px;
  margin-top: -15px;
  background: var(--accent-color); 
  border: 0px;
  border-radius: 50%;
  z-index: 1;
  pointer-events:auto;
}
input[type='range']:nth-child(1)::-webkit-slider-thumb{
  z-index: 1;
}
.okbg-hs-rangeslider{
    position: relative;
    height: 80px;
    width: 100%;
    max-width: 500px;
    margin: 0px;
}
.okbg-hs-rangeslider input{
    position: absolute;
}

.okbg-hs-rangeslider input.left{
  width: 90px;
  position: absolute;
  margin-top: 50px;
  left: 0px;
  text-align: right;
}
.okbg-hs-rangeslider input.right{
  width: 90px;
  position: absolute;
  margin-top: 50px;
  right: 0px;
  text-align: right;
}
.okbg-hs-prev-next{  
  margin: 10px;
  vertical-align: middle;
  background: var(--color-text);
  color: var(--color-border);
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  padding: 0px 15px;
  border-radius: 10px;
  cursor: pointer;
  padding-bottom: 10px;
}
.okbg-hs-prev-next:hover{
  background: var(--color-border);
  color: var(--color-text);
}
.okbg-hs-hide{
  display: none;
}
.okbg-hs-error-validate{
  display: block;
  color: var(--accent-color);
}
.okbg-hs-pinned{
  border: 0px;
  border-top: 1px;
  border-color: var(--color-border);
  border-style: solid;
  background: var(--bg-panel);
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.pinnedbtns{
  text-align: center;
  background-color: var(--accent-color);
  color: var(--bg-panel);
  font-size: 12px !important;
}
.pinnedbtns:hover{
  background-color: var(--color-text);
  color: var(--bg-panel);
}
.okbg-hs-video-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.okbg-hs-video-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
.kep-login-facebook{
  width: 100%;
}
.saved-label{
  width: 65px;
  display: block; 
  font-size: 10px; 
  text-align: center;
  font-weight: bold;
  color: var(--accent-color);
}
#top-user-button{
  color: white;
  display: block;
  height: 28px;
  line-height: 26px;
  background-size: 30px 30px;
  padding: 0px 10px 2px 30px;
  border: 1px solid white;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-position: -2px -2px;
  margin-right: 5px;
  font-size: 11px;
  white-space:nowrap;
  overflow: hidden;
  max-width: 130px;
}
